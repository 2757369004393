export enum MobileCoreRoutes {
  root = 'core',
  main = '',
  error = 'error',
}

export function getCoreRoute(route: MobileCoreRoutes): string {
  return route.length > 0
    ? MobileCoreRoutes.root + '/' + route
    : MobileCoreRoutes.root;
}
